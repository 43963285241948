import { useEffect, useState } from "react";
import { Button, Col, Container, Row, FormText } from "react-bootstrap"
import { useDispatch } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom"
import { useCheckTokenExpiryTimeQuery, useResetPasswordMutation } from "../redux-services/apis/authApi";
import { showModel } from "../redux-services/slices/siteDataSlice";
import ShowError from "./Notifications/ShowError";
import { FaRegEyeSlash, FaRegEye } from "react-icons/fa";
import ShowModel from "./Notifications/ShowModel";
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

export const ResetPassword = () => {
    const { id, token } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const [errors, setErrors] = useState({});
    const [passwordShown, setPasswordShown] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState(false);
    const [hideForm, setHideForm] = useState(false);
    const [formData, setFormData] = useState({
        password: '',
        confirm_password: ''
    });
    const onChange = (e) => {
        setFormData({
            ...formData, [e.target.name]: e.target.value?.trimStart()
        });
    };

    const togglePassword = (e) => {
        e.preventDefault();
        if (e.currentTarget.getAttribute("data-id") == "password") {
            setPasswordShown(!passwordShown);
            setConfirmPassword(confirmPassword);
        }
        if (e.currentTarget.getAttribute("data-id") == "confirm_password") {
            setPasswordShown(passwordShown);
            setConfirmPassword(!confirmPassword);
        }
    };

    const setFocusOn = (fieldId) => {
        const fieldItem = document.querySelector("input[name=" + fieldId + "]");
        fieldItem?.focus();

    }
    const [resetPassword, resetPasswordResponse] = useResetPasswordMutation();
    const { data: tokenExpiryRes, error: errorRes } = useCheckTokenExpiryTimeQuery({ id, token }, {
        skip: !id,
    }, {
        skip: !token,
    })



    useEffect(() => {
        if (resetPasswordResponse?.isSuccess && resetPasswordResponse?.data?.status) {
            setFormData({
                password: '',
                confirm_password: ''
            });
            setTimeout(() => {
                dispatch(showModel({ isOpen: true, modelType: "success", body: resetPasswordResponse.data.message }))

            }, 2000)
            navigate("/login")
        }
        if (resetPasswordResponse?.isError && resetPasswordResponse?.error?.data?.errors) {
            const errorList = {};
            let firstError = "";
            dispatch(showModel({ isOpen: true, modelType: "danger", body: resetPasswordResponse.error.data.message }))
            resetPasswordResponse?.error?.data?.errors.forEach((error, index) => {
                if (index === 0) {
                    firstError = error.param;
                }
                errorList[error.param] = error.msg;
            });

            setErrors(errorList);
            setFocusOn(firstError);
        }
        if (errorRes?.status === 403) {
            setHideForm(true);
            dispatch(showModel({ isOpen: true, modelType: "danger", body: errorRes?.data?.message }))
        }
        if (tokenExpiryRes?.status === true) {
            formData.id = tokenExpiryRes?.response._id;
            formData.email = tokenExpiryRes?.response.email;
        }
    }, [tokenExpiryRes, errorRes, resetPasswordResponse.isError, resetPasswordResponse.isSuccess])

    const submitHandler = (event) => {
        event.preventDefault();
        setErrors({});
        resetPassword(formData)
    }

    return (
        <>
            <section className="loginOuter">
                <Container>
                    <Row className="justify-content-center">
                        <Col md="12">
                            <ShowModel />
                        </Col>
                        {!hideForm && <Col md="8" lg="6" xl="4">
                            <div className="logoOuter">
                                <Link to="">
                                    <img src="/images/logo.svg" />
                                </Link>
                            </div>

                            <div className="loginformOuter">
                                <h4>Reset Password</h4>
                                <hr />
                                <form onSubmit={submitHandler} autoComplete="off">
                                    <div className="form-group special_pass">
                                        {formData.password == '' &&
                                            <Tooltip anchorSelect=".my-anchor-element1" place="bottom">
                                                Password must contain at least 8 characters, including Upper/lowercase, numbers and special characters
                                            </Tooltip>
                                        }
                                        <input type={(passwordShown) ? "text" : "password"} value={formData.password} required name="password" onChange={onChange} placeholder="Enter new password" className="form-control my-anchor-element1" />
                                        <span className='viewpass' onClick={togglePassword} data-id="password" >{(passwordShown) ? <FaRegEyeSlash /> : <FaRegEye />}</span>
                                        {/* <ShowError current_key="password" key="password" errors={errors} /> */}
                                    </div>
                                    {errors && errors?.password !== undefined &&
                                        <div id="message" style={{ marginBottom: '12px' }}>
                                            <FormText style={{ fontSize: "10px", marginTop: "-10px" }} className='text-danger'> Password requirements:</FormText>
                                            <FormText>
                                                <li style={{ fontSize: "10px" }} className='text-danger'>Must contain at least 8 characters</li>
                                                <li style={{ fontSize: "10px" }} className='text-danger'>At least 1 uppercase letter</li>
                                                <li style={{ fontSize: "10px" }} className='text-danger'>At least 1 number</li>
                                                <li style={{ fontSize: "10px" }} className='text-danger'>At least 1 special character (@$!%*#?&.)</li>
                                            </FormText>
                                        </div>
                                    }
                                    <div className="form-group">
                                        {formData.confirm_password == '' &&
                                            <Tooltip anchorSelect=".my-anchor-element2" place="bottom">
                                                Password must contain at least 8 characters, including Upper/lowercase, numbers and special characters
                                            </Tooltip>
                                        }
                                        <input type={(confirmPassword) ? "text" : "password"} value={formData.confirm_password} required name="confirm_password" onChange={onChange} placeholder="Enter confirm password" className="form-control my-anchor-element2" />
                                        <span className='viewpass' onClick={togglePassword} data-id="confirm_password" >{(confirmPassword) ? <FaRegEyeSlash /> : <FaRegEye />}</span>
                                        <ShowError current_key="confirm_password" key="confirm_password" errors={errors} />
                                    </div>
                                    <div className="form-group text-center">
                                        <Button type="submit" >Submit</Button>
                                    </div>
                                </form>
                                <div className="account">
                                    <p>  <Link to="/login">Go To Login.</Link></p>
                                </div>
                            </div>
                        </Col>}
                    </Row>
                </Container>
            </section>
        </>
    )
}